@mixin ep-text-button-styles() {
  @include f-helvetica-bold;
  display: inline-flex;
  padding: 0;

  color: var(--c-text);
  width: auto;
  transition: $transition-base;
  text-decoration: none;

  text-align: left;
  border: 0;
  border-radius: 0;
  background: transparent;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  > * {
    vertical-align: middle;
  }

  .icon {
    width: rem(18);
    height: rem(18);
    margin-right: em(8, 16);
    transition: $transition-base;
    transform: scale(1);
    fill: currentColor;

    align-self: center;
    flex: 0 0 auto;

    &:last-child {
      margin-left: em(8, 16);
      margin-right: 0;
    }
  }

  span {
    padding: 5px 0 3px;

    background-image: linear-gradient(to right, var(--c-link), var(--c-link));
    background-repeat: no-repeat;
    background-size: 100% 1px;
    background-position: 0 100%;

    transition: $transition-base;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    box-shadow: none;

    color: var(--c-text);
    transform: translateY(-2px);

    .icon {
      $scale: 24 / 18;
      transform: scale(#{$scale});
    }

    span {
      background-size: 100% 3px;
    }
  }
}

.txt-btn {
  @include ep-text-button-styles();
}
